import 'izitoast/dist/css/iziToast.min.css'
import iziToast from "izitoast";

const toast = {
    error: (message) => {
        return iziToast.error({
            message: message,
            position: "bottomCenter",
            timeout: 3000,
            backgroundColor: 'rgba(245, 154, 154, 0.8)'
        })
    },
    success: (message) => {
        return iziToast.success({
            message: message,
            position: "bottomCenter",
            timeout: 6000,
            backgroundColor: 'rgba(152, 233, 152, 0.8)'
        })
    }
}
export default toast