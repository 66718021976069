<template>

</template>

<script>
import XLSX from 'xlsx';
export default {
  name: "VueExcel",
  props: {
    data: Array,
  },
  methods: {
    downloadExcelSheet(data,name,options) {
      options = (options !== undefined ? options : {})
      const animalWS = XLSX.utils.json_to_sheet(data,options);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, animalWS, 'report');
      XLSX.writeFile(wb, name + '.xlsx');
    }
  }
}
</script>

<style scoped>

</style>