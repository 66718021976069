<template>
  <v-app>
    <router-view v-if="$route.meta.auth === false"></router-view>
    <div class="main-outer-blk" >
      <router-view v-if="$route.meta.auth === true"></router-view>
    </div>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Login from "@/components/Login/Login";

export default {
  name: 'App',

  components: {
    Login,
    // HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "scss/common";
@import url('https://fonts.googleapis.com/css?family=Rokkitt:100,200,300,400,500,600,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800&display=swap');
#app {
  height: 100vh;
  //font-family: 'Avenir',sans-serif;
  font-family: "Muli", sans-serif;
  font-size: 15px;
}


//::-webkit-scrollbar {
//  width: 0;
//}
@media only screen and (min-width: 992px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #d4d4d4 #f7f7f7;
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: #f7f7f7;
    width: 10px;
  }
  *::-webkit-scrollbar-thumb {
    width: 10px;
    background-color: #6d6d6d;
  }

  *::-webkit-scrollbar:horizontal{
    height: 10px;
    background-color: #f7f7f7;
  }
  *::-webkit-scrollbar-thumb:horizontal{
    background: #6d6d6d;
    border-radius: 0;
  }
  html,
  body {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
